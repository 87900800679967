import { showNotify } from 'vant';
import { getHExamineList, exportReportHExamine } from '@/api/global';
export default {
  data() {
    return {
      active: 1,
      form: {
        number: null,
        ProjectName: null,
        CustomerName: null,
        SupplierName: null,
        ProductName: null,
        SpecificationName: null,
        sales_date1: null,
        sales_date2: null,
        status: 0
      },
      collectionlist: [],
      collectionlists: [],
      currentPage: 1,
      total: 0,
      perpage: 50,
      pagesize: 50,
      isshow: false
    };
  },
  created() {
    const codeg = this.$route.query.code;
    if (codeg) {
      window.localStorage.setItem('code', codeg);
      this.search();
    } else {
      this.getredirect();
    }
  },
  methods: {
    getredirect() {
      const REDIRECTURIs = 'https://fk.gangerp.com/examinelist';
      const REDIRECTURIa = encodeURIComponent(REDIRECTURIs);
      const url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww60a7e569d504e44f&redirect_uri=' + REDIRECTURIa + '&response_type=code&scope=snsapi_base&state=STATE&agentid=AGENTID#wechat_redirect';
      location.href = url;
    },
    onClickTab(title) {
      if (title.title === '已处理') {
        this.form.status = 1;
        this.search();
      } else {
        this.form.status = 0;
        this.search();
      }
    },
    search() {
      const code = window.localStorage.getItem('code');
      this.updateLoading = true;
      this.loading = true;
      getHExamineList({
        data: this.form,
        page: this.currentPage,
        per: this.perpage,
        code: code
      }).then(res => {
        if (res.data.status === 'success') {
          this.collectionlist = res.data.data;
          this.currentPage = res.data.page;
          this.total = res.data.total;
          this.counts = res.data.counts;
          this.loading = false;
          this.updateLoading = false;
          this.isshow = false;
        } else {
          this.loading = false;
          this.updateLoading = false;
          this.isshow = true;
        }
      }).catch(err => {
        this.loading = false;
        this.updateLoading = false;
        console.log('保存失败', err);
      });
    },
    exportReportsale(index, type) {
      const code = window.localStorage.getItem('code');
      exportReportHExamine({
        SellBillNumber: this.collectionlist[index].SellBillNumber,
        PurchaseBillNumber: this.collectionlist[index].PurchaseBillNumber,
        type: this.collectionlist[index].type,
        types: this.collectionlist[index].types,
        code: code
      }).then(res => {
        if (res.data.status === 'success') {
          this.search();
          if (type === 1) {
            showNotify({
              type: 'success',
              message: '签章成功'
            });
          } else {
            showNotify({
              type: 'success',
              message: '取消签章成功'
            });
          }
          if (this.collectionlist[index].types === '泊明') {
            if (this.collectionlist[index].type === '销售') {
              this.dialogVisiblessale = true;
            } else {
              this.dialogVisiblespurchase = true;
            }
          } else {
            if (this.collectionlist[index].type === '销售') {
              this.dialogVisiblessale = true;
            } else {
              this.dialogVisiblespurchase = true;
            }
          }
        } else {
          showNotify({
            type: 'warning',
            message: '您没有权限操作'
          });
        }
      }).catch(err => {
        console.log('失获取数据败', err);
      });
    }
  }
};