import request from '@/utils/request'
// 栏目
export const getCollectionList = data => {
	return request({
		method: 'POST',
		url: '/api/getCollectionListAll',
		data
	})
}
export const getDetail = data => {
	return request({
		method: 'POST',
		url: '/api/getDetail',
		data
	})
}
export const getPaymentList = data => {
	return request({
		method: 'POST',
		url: '/api/getPaymentListAll',
		data
	})
}
export const getHExamineList = data => {
	return request({
		method: 'POST',
		url: '/api/getHExamineList',
		data
	})
}
export const exportReportHExamine = data => {
	return request({
		method: 'POST',
		url: '/api/exportReportHExamine',
		data
	})
}
