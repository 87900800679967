import { createRouter, createWebHistory } from 'vue-router'
import Collection from '@/views/finance/collection.vue'
import Payment from '@/views/finance/payment.vue'
import Detail from '@/views/finance/detail.vue'
import Examinelist from '@/views/sale/examinelist.vue'

const routes = [
  {
    path: '/collection',
    name: 'collection',
    component: Collection
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment
  },
  {
    path: '/examinelist',
    name: 'examinelist',
    component: Examinelist
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
